.widget {
  background-color: #ffffff;
  padding: 15px 20px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 57, 78, 0.05);
  height: 100%;
}
.widget-img {
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 15px;
  background-color: #dae2ff;
}
.widget-img img {
  width: 30px;
  height: 30px;
}

.chart-wrapper {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 57, 78, 0.05);
}
.total-numbers {
  position: relative;
  padding-left: 12px;
}
.total-numbers::before {
  position: absolute;
  content: "";
  left: 0;
  top: 2px;
  width: 4px;
  border-radius: 5px;
  height: 90%;
}
.total-numbers.live::before {
  background-color: #08a975;
}
.total-numbers.partial::before {
  background-color: #ff9900;
}
.total-numbers.lite::before {
  background-color: #1f77b4;
}
.total-numbers.successful::before {
  background-color: #456dff;
}
.total-numbers.failed::before {
  background-color: #ec6969;
}
.total-numbers.canceled::before {
  background-color: #dfe4eb;
}
