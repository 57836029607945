.add-limit-feild {
  width: 150px;
  padding-right: 40px;
}
.setting-tabs .nav-tabs {
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
}
.setting-tabs .nav-tabs .nav-link {
  color: #6c757d;
  border: none;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  margin-bottom: 0 !important;
}
.setting-tabs .nav-tabs .nav-link.active {
  color: #24272c;
  border: none;
  border-bottom: 3px solid #456dff;
}
.cancel-btn {
  position: absolute;
  right: 20px;
  top: 0;
  bottom: 0;
}
