.navbar {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
  box-shadow: 0 10px 30px rgba(0, 57, 78, 0.05);
}
.dropdown:focus-visible {
  outline: none !important;
}
.dropdown-menu {
  min-width: 270px !important;
  border-radius: 15px;
  border: 1px solid #dfe4eb;
  box-shadow: 0 10px 30px rgba(0, 57, 78, 0.05);
  font-size: 13.6px !important;
}
.dropdown-menu .profile {
  width: 50px;
  height: 50px;
  min-height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.dropdown-profile {
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 50%;
  overflow: hidden;
}
.dropdown-item {
  display: flex !important;
  gap: 15px;
  align-items: center;
  color: #24272c;
  font-weight: 500;
  padding: 10px 24px;
}
.dropdown-item svg {
  fill: #a9b2bd;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #dae2ff !important;
  color: #24272c !important;
}
.dropdown-item:focus svg,
.dropdown-item:hover svg {
  fill: #456dff !important;
}
.dropdown-toggle:focus,
.dropdown:focus,
.dropdown-item:focus {
  outline: none;
}
.dropdown-toggle::after {
  display: none !important;
}

.modal-md {
  max-width: 620px !important;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 20px 30px !important;
}

.modal-title {
  font-size: 15.3px;
}

.modal-header .btn-close {
  margin-right: 0 !important;
  padding: 0 !important;
}

.modal-footer {
  gap: 10px;
  justify-content: flex-start !important;
}

.modal-footer {
  border-top: 0 !important;
}

::placeholder {
  color: #24272c !important;
}

.modal-profile-img {
  width: 120px;
  height: 12px;
  min-width: 120px;
  min-height: 120px;
  border-radius: 50%;
  border: 1px solid #dfe4eb;
}
.modal-profile-img .profile-img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.remove-img-btn {
  border: 1px solid #dfe4eb !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  color: #6c757d !important;
  background-color: #f9fafb !important;
}

.avatar-edit {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.avatar-edit input {
  display: none;
}

.avatar-edit input + label {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #456dff;
  border: 1px solid #dfe4eb;
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  background-image: url(../images/profile-edit.svg);
  background-size: 12px;
  background-position: center;
  background-repeat: no-repeat;
}

button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

.modal-content {
  border-radius: 15px !important;
  box-shadow: 0 10px 30px rgba(0, 57, 78, 0.05);
}

@media (max-width: 575px) {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding: 20px 20px !important;
  }
}

.bg-transparent {
  background-color: transparent !important;
  color: black !important;
  border: unset !important;
}
