.css-1wvake5 {
  position: fixed !important;
  width: 270px !important;
  min-width: 270px !important;
  height: 100%;
  background-color: white;
  border: none !important;
  z-index: 2;
}
.css-1wvake5.ps-collapsed {
  width: 80px !important;
  min-width: 80px !important;
}
.css-1wvake5.ps-collapsed .navbar-brand {
  display: none;
  transition: all 300ms;
}
.body-wrapper {
  padding-left: 270px;
  transition: all 300ms;
}
.css-1wvake5.ps-collapsed ~ .body-wrapper {
  padding-left: 80px;
}
.css-ewdv3l {
  padding: 15px !important;
}
.css-dip3t8 {
  background-color: white !important;
}
.navbar-brand {
  height: 35px !important;
  max-width: 200px !important;
  display: block;
  transition: all 300ms;
}
.sidenav-header {
  padding: 20px 20px 15px 20px;
  height: 70px;
}
.css-1t8x7v1 > .ps-menu-button {
  padding: 0 !important;
}
.ps-menu-label a {
  padding: 10px 15px !important;
  border-radius: 10px;
  margin-bottom: 5px;
  display: flex !important;
  gap: 15px;
  align-items: center;
  color: #6c757d;
  font-weight: 500;
}
.ps-menu-label a svg {
  fill: #a9b2bd;
  height: 20px;
  min-width: 20px;
}
.close-menu {
  padding-left: 12px;
  cursor: pointer;
}
.ps-menu-label a.active,
.ps-menu-label a:hover {
  background-color: #dae2ff !important;
  color: #24272c !important;
}
.ps-menu-label a.active svg,
.ps-menu-label a:hover svg {
  fill: #456dff !important;
}
.ps-menu-label a:focus,
.ps-menu-button:focus {
  outline: none;
}
.css-1t8x7v1 > .ps-menu-button:hover {
  background-color: transparent !important;
}
.bg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
}
@media (max-width: 991px) {
  .css-1wvake5.ps-collapsed ~ .body-wrapper {
    padding-left: 0 !important;
  }
  .css-1wvake5.ps-collapsed .navbar-brand {
    display: block;
    transition: all 300ms;
  }
  .body-wrapper {
    padding-left: 0 !important;
  }
  .close-menu {
    z-index: 1;
    position: absolute;
    top: 20px;
  }
  .css-1wvake5.ps-collapsed {
    width: 270px !important;
    min-width: 270px !important;
    left: 0 !important;
  }
  .bg-overlay {
    display: none;
  }
  .css-1wvake5.ps-collapsed ~ .bg-overlay {
    display: block;
  }
  .css-1wvake5 {
    top: 0;
    left: -270px !important;
  }
}
