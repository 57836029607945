.action-icon {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 10px !important;
  border: 1px solid #e9e9e9 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: white !important;
}
.form-check .form-check-input {
  float: none !important;
}
.calls-label {
  min-width: 80px;
}
.data-type {
  padding: 10px 25px;
  border: 1px solid #dfe4eb;
  background-color: transparent;
  color: #6c757d;
  border-radius: 23px;
  cursor: pointer !important;
  display: flex;
  gap: 10px;
}
.checked-img {
  display: none;
}
.select-radio:checked + .data-type {
  border: 1px solid #456dff;
  background-color: #456dff;
  background-position: right;
  color: #ffffff;
}
.select-radio:checked + .data-type .checked-img {
  display: block;
}
.select-radio {
  display: none;
}
.regenerate-icon {
  position: absolute;
  top: 10px;
  right: 50px;
}
/* .dashboard-back-btn {
  order: 3;
}
.date-rangepicker {
  order: 2;
} */
@media (max-width: 768px) {
  /* .dashboard-back-btn {
    order: 2;
  } */
  /* .date-rangepicker {
    order: 3;
  } */
}
