.login-page {
  background-color: #f4fafd;
  position: relative;
  z-index: 2;
}
.company-logo {
  height: 42.5px;
}
.login-form {
  max-width: 490px;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 57, 78, 0.05);
}
.scooter-img {
  position: absolute;
  left: 20px;
  bottom: 0;
  z-index: -1;
  max-width: 45%;
}
.taxi-img {
  position: absolute;
  right: 20px;
  bottom: 0;
  z-index: -1;
  max-width: 45%;
}
.left-img {
  position: absolute;
  left: 0;
  top: 10%;
  z-index: -1;
  max-width: 15%;
}
.right-img {
  position: absolute;
  right: 0;
  top: 10%;
  z-index: -1;
  max-width: 15%;
}
.icon svg {
  width: 20px;
}
@media (max-width: 575px) {
  .company-logo {
    height: 34px;
  }
  .login-form {
    padding: 20px;
  }
  .scooter-img {
    left: 0;
  }
  .taxi-img {
    right: 0;
  }
}
