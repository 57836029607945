body {
  font-family: "Metropolis", sans-serif !important;
  height: 100vh;
  color: #24272c !important;
  background-color: #f4fafd !important;
  font-size: 13.6px;
}

.go3958317564 {
  font-size: 13.6px !important;
}

#root {
  height: 100%;
}

.text-gray-600 {
  color: #6c757d !important;
}

.text-primary {
  color: #456dff;
}

.text-success {
  color: #08a975 !important;
}

.text-warning {
  color: #ff9900 !important;
}

.text-danger {
  color: #ec6969 !important;
}

.bg-gray-100 {
  background-color: #f5f5f5 !important;
}

.bg-gray-900 {
  background-color: #24272c !important;
}

.mb-80px {
  margin-bottom: 80px;
}

.mb-60px {
  margin-bottom: 60px;
}

.mb-30px {
  margin-bottom: 30px;
}

.fs-14px {
  font-size: 12px;
}

.fs-16px {
  font-size: 13.6px;
}

.fs-18px {
  font-size: 15.3px;
}

.fs-22px {
  font-size: 18.7px;
}
h4 {
  font-size: 20.4px;
}
h3 {
  font-size: 23.8px;
}
.py-30px {
  padding-top: 30px;
  padding-bottom: 30px;
}

.right-0 {
  right: 0;
}

@font-face {
  font-family: "Metropolis";
  src: url("../font/Metropolis-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Metropolis";
  src: url("../font/Metropolis-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Metropolis";
  src: url("../font/Metropolis-SemiBold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Metropolis";
  src: url("../font/Metropolis-Bold.otf") format("opentype");
  font-weight: 700;
}

.btn {
  border-radius: 23px !important;
  padding: 10px 30px !important;
  font-size: 13.6px !important;
  font-weight: 500 !important;
}

.btn:focus .btn:active,
.btn:focus-visible {
  box-shadow: none !important;
}

.btn-primary {
  color: white !important;
  background-color: #456dff !important;
  border: 1px solid #456dff !important;
}

.btn-secondary {
  color: #6c757d !important;
  background-color: #dfe4eb !important;
  border: 1px solid #dfe4eb !important;
}

.btn-primary-light {
  color: #456dff !important;
  background-color: transparent !important;
  border: 1px solid #456dff !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.form-control {
  padding: 10px 20px;
  border-radius: 23px !important;
  border: 1px solid #dfe4eb !important;
  box-shadow: none !important;
}

.form-control:focus {
  outline: none !important;
}

.section-heading h2 {
  font-size: 18.7px;
}

.table-heading {
  padding: 20px;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-bg-type: #ffffff !important;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  --bs-table-bg-type: #f9fafb !important;
}

.object-fit-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0 !important;
}

.datepicker {
  min-width: 276px;
  font-size: 13.6px;
  font-weight: 500 !important;
  color: #6c757d !important;
  padding: 10px 20px 10px 40px !important;
}

.date-icon {
  position: absolute;
  top: 9px;
  left: 20px;
}

.flex-1 {
  flex-grow: 1;
}

.w-70 {
  width: 65%;
}

.w-15 {
  width: 15%;
}

.table > tbody {
  vertical-align: middle !important;
}

.wrapper {
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 57, 78, 0.05);
}

a {
  text-decoration: none !important;
}

.pagination-section {
  padding: 30px 25px;
}

.page-link {
  border: none !important;
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  font-weight: 500;
  margin: 0 3px !important;
  color: #6c757d !important;
}

.page-link:focus,
.page-link.active,
.page-link:hover {
  box-shadow: none !important;
  background-color: #456dff !important;
  color: #ffffff !important;
}

.form-switch .form-check-input {
  width: 40px;
  height: 22px;
  --bs-form-check-bg: #ced4da !important;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.form-check-input:focus {
  box-shadow: none !important;
  border: 1px solid #dee2e6 !important;
}

.alert-warning {
  background-color: #fff1d7 !important;
  border: 1px solid #ff9900 !important;
  color: #24272c !important;
}

.warning-alert-icon {
  width: 30px !important;
  height: 26px !important;
  fill: #ff9900 !important;
}

.badge {
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 13.6px !important;
  font-weight: 500 !important;
}

.successful-badge {
  background-color: #ceeee3 !important;
  color: #08a975 !important;
}

.primary-badge {
  background-color: #dae2ff !important;
  color: #6486ff !important;
  font-size: 11px !important;
}

.partial-badge {
  background-color: #fff1d7 !important;
  color: #ff9900 !important;
}

.failed-badge {
  background-color: #fbe1e1 !important;
  color: #ec6969 !important;
}

.link-arrow {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a9b2bd;
  color: white;
}

.link-arrow svg {
  width: 20px;
  height: 12px;
  stroke-width: 0.8;
}

.statistics-table thead tr th {
  padding: 8px 20px;
  font-size: 13.6px;
  font-weight: 500;
  color: #6c757d;
  background-color: #f9fafb;
  border-top: 1px solid #dfe4eb;
  border-bottom: 1px solid #dfe4eb;
}

.statistics-table tbody tr td {
  padding: 9px 20px;
  font-weight: 500;
  color: #24272c;
}

.statistics-table tfoot tr td {
  border-top: 1px solid #dfe4eb;
  padding: 12px 20px;
  font-weight: 600;
  color: #24272c;
}

.logs-table thead tr th {
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 500;
  color: #6c757d;
  background-color: #f9fafb;
  border-top: 1px solid #dfe4eb;
  border-bottom: 1px solid #dfe4eb;
}

.logs-table tbody tr td {
  padding: 15px 20px;
  font-weight: 500;
  color: #24272c;
  font-size: 14px;
}

.search-box {
  max-width: 280px;
}

.search-box [type="search"] {
  padding: 7px 15px 7px 40px !important;
  color: #6c757d !important;
  font-weight: 500;
}

.search-box [type="search"]::placeholder {
  color: #6c757d !important;
}

.search-box .search-icon {
  position: absolute;
  top: 7px;
  left: 15px;
}

.search-box .search-icon svg {
  fill: #a9b2bd !important;
  stroke-width: 0.6;
  stroke: #a9b2bd !important;
}

.css-b62m3t-container {
  min-width: 200px;
}

.css-1xc3v61-indicatorContainer,
.css-15lsz6c-indicatorContainer {
  color: #6c757d !important;
  padding: 0 !important;
}

.css-1fdsijx-ValueContainer {
  padding: 0 !important;
}

.css-t3ipsp-control {
  box-shadow: none !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-13cymwt-control,
.css-t3ipsp-control {
  border-radius: 23px !important;
  border: 1px solid #dfe4eb !important;
  cursor: pointer !important;
  padding: 10px 16px !important;
}
.css-19bb58m {
  margin: 0 !important;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
.css-hlgwow {
  padding: 0 !important;
}
.css-1xc3v61-indicatorContainer,
.css-1xc3v61-indicatorContainer {
  margin-left: 3px !important;
}
.css-qbdosj-Input {
  padding: 0 !important;
  margin: 0 !important;
}

.editor-tabs .tab-content {
  padding: 16px 24px;
  background-color: #24272c;
  color: white;
}

.editor-tabs .nav-tabs {
  flex-wrap: nowrap;
  overflow: auto;
}

.editor-tabs .nav-tabs .nav-link {
  border-radius: 23px;
  padding: 7px 20px;
  margin: 0;
  color: #6c757d;
  font-weight: 500;
  border: none !important;
}

.editor-tabs .nav-tabs .nav-link.active {
  background-color: #456dff;
  color: white;
}

.modal-sm {
  max-width: 460px !important;
  margin: auto;
}

.max-w-380px {
  max-width: 380px;
}

.max-w-296px {
  max-width: 296px;
}

.w-160px {
  width: 150px;
  min-width: 130px;
}

.add-slab-table .form-control {
  min-width: 90px;
}

.alert-icon {
  max-width: auto;
  height: 70px;
}

.total {
  background-color: #f9fafb;
  border-radius: 15px;
  padding: 15px 20px;
  border: 1px solid #dfe4eb;
}

.w-20 {
  width: 20%;
  min-width: 170px;
}

.w-40 {
  width: 40%;
  min-width: 230px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: relative;
  background-image: url("../images/datepicker.svg");
  cursor: pointer;
}

.form-select {
  padding: 7px 30px 7px 16px;
  border-radius: 20px !important;
  box-shadow: none !important;
  width: 70px;
  border: 1px solid #dfe4eb !important;
  font-weight: 600 !important;
  color: #6c757d !important;
}

/* datepicker */
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays {
  background: #456dff !important;
}

span.flatpickr-weekday {
  background: #456dff !important;
  color: white !important;
}

.flatpickr-day.inRange {
  box-shadow:
    -5px 0 0 #dae2ff,
    5px 0 0 #dae2ff !important;
  background: #dae2ff !important;
  border-color: #dae2ff !important;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 #dae2ff !important;
}

.flatpickr-day:hover,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected {
  background: #456dff !important;
  border-color: #456dff !important;
  color: white !important;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #456dff !important;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: white !important;
}

.flatpickr-day.today {
  border: none !important;
}

.flatpickr-months .flatpickr-month {
  height: 43px !important;
}

.flatpickr-current-month {
  height: 43px !important;
  padding-top: 14px !important;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  height: 43px !important;
  padding: 11px 10px !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: none !important;
  min-width: 120px !important;
  background-image: url(../images/down-arrow.svg) !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
  background-size: 9px !important;
}

.flatpickr-current-month input.cur-year {
  padding: 0 0 0 0.7ch !important;
}

.billing-modal table thead th {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
}

.billing-modal table tbody td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* flatpickr */
.flatpickr-monthSelect-month {
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin: 0.5px;
  justify-content: center;
  padding: 10px;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  width: 33%;
}

.flatpickr-monthSelect-month.selected,
.flatpickr-monthSelect-month.startRange,
.flatpickr-monthSelect-month.endRange {
  background-color: #456dff;
  box-shadow: none;
  color: #fff;
  border-color: #456dff;
}

.flatpickr-monthSelect-month:hover,
.flatpickr-monthSelect-month.today:hover,
.flatpickr-monthSelect-month.today:focus {
  background: #456dff;
  border-color: #456dff;
  color: #fff;
}

/* rangepicker */
.rdrDateRangePickerWrapper {
  position: absolute;
  right: -8px;
  z-index: 4;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 57, 78, 0.05);
  overflow: hidden;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: #456dff !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge,
.rdrDayToday .rdrDayNumber span:after {
  background-color: #456dff !important;
}

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,
.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
  background-color: #fff !important;
}

/* .rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  border: 0px solid #456dff !important;
} */

.rdrDateDisplayItemActive {
  border-color: #456dff !important;
}
@media (max-width: 1182px) {
  .rdrMonths {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .rdrDateRangePickerWrapper {
    flex-direction: column;
    right: 0;
  }

  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }
}

@media (max-width: 374px) {
  .rdrDateRangePickerWrapper {
    width: 280px;
  }

  .rdrMonth {
    width: 23.667em !important;
  }
}

@media (max-width: 1199px) {
  .order-xl-2 {
    order: 3 !important;
  }

  .order-xl-3 {
    order: 2 !important;
  }

  .min-w-35 {
    min-width: 35% !important;
  }
}

@media (max-width: 991px) {
  .mb-80px {
    margin-bottom: 50px;
  }

  .mb-60px {
    margin-bottom: 40px;
  }

  .body-wrapper {
    padding-left: 0;
  }
}

@media (max-width: 636px) {
  .modal-md {
    margin: auto 0.5rem;
  }
}

@media (max-width: 575px) {
  .fs-16px {
    font-size: 14px !important;
  }

  .fs-18px {
    font-size: 16px;
  }

  .fs-22px {
    font-size: 20px;
  }
}

@media (max-width: 474px) {
  .modal-sm {
    margin: auto 0.5rem;
  }
}

.w-20 {
  width: "20%" !important;
}

.kanban-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.kanban-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.kanban-item:focus-visible {
  outline: unset;
}

.list-item {
  padding: 12px 20px;
  background-color: #f9fafb;
  border: 1px solid #dfe4eb;
  border-radius: 10px;
}

.kanban-item-remove {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e9e9e9;
}

.add-list-btn {
  padding: 5px 18px !important;
}

.nav-link:focus-visible {
  box-shadow: none !important;
}

.btn:focus-visible {
  outline: 0 !important;
  box-shadow: unset !important;
}

.custom-datepicker {
  padding: 10px 40px 10px 20px !important;
}

.custom-rangepicker .date-icon {
  right: 20px !important;
  left: auto !important;
}

.editor-tabs .tab-content {
  max-height: 284px !important;
  height: 284px !important;
  overflow-y: auto !important;
}

.partner-tabs .tab-content,
.vendor-tabs .tab-content {
  max-height: 396px !important;
  height: 396px !important;
  overflow-y: auto !important;
}

.rdrDateDisplay,
.rdrInputRanges {
  display: none !important;
}
.status-tab.nav-tabs {
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
}
.status-tab.nav-tabs .nav-link {
  color: #6c757d;
  border: none;
  font-weight: 500;
  border-bottom: 3px solid transparent;
  margin-bottom: 0 !important;
}
.status-tab.nav-tabs .nav-link.active {
  color: #24272c;
  border: none;
  border-bottom: 3px solid #456dff;
}

.statistics-table tfoot {
  position: sticky;
  bottom: 0;
  background-color: white;
}

.statistics-table thead {
  position: sticky;
  top: 0;
  background-color: white;
}

.partner-dashboard-statastics thead::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1;
  border-bottom: 1px solid #dfe4eb;
  border-top: 1px solid #dfe4eb;
}

svg:focus {
  outline: none;
}

.action-header-icon {
  border-radius: 10px !important;
  border: 1px solid #e9e9e9 !important;
  background-color: white !important;
  padding: 10px 20px !important;
}
.rmdp-panel.right {
  display: none !important;
}
.rmdp-day.rmdp-today span,
.rmdp-range,
.rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
  background-color: #456dff !important;
}
.rmdp-ym .rmdp-range-hover.start:not(.force),
.rmdp-ym .rmdp-range.start:not(.force) {
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important;
}
.rmdp-ym .rmdp-range-hover.end:not(.force),
.rmdp-ym .rmdp-range.end:not(.force) {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}
.rmdp-day span {
  border-radius: 6px !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}
.only.rmdp-month-picker,
.only.rmdp-year-picker {
  width: 100% !important;
}
.rmdp-shadow,
.rmdp-ep-shadow:after {
  box-shadow: 0 3px 13px #00000014 !important;
}
.rmdp-calendar {
  min-width: 290px;
}
.rangepicker .rmdp-container {
  padding: 10px 16px;
  border-radius: 23px !important;
  border: 1px solid #dfe4eb !important;
  cursor: pointer !important;
  background-color: white;
}
.rangepicker .date-icon {
  top: 12px;
}
.rmdp-input {
  margin: 0;
  padding: 0;
  padding-left: 36px !important;
  border: none !important;
  height: 100%;
  cursor: pointer;
  caret-color: transparent;
}
.rmdp-input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
.rmdp-border-right {
  border-right: 0 !important;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
}
